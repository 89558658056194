<template>
  <div v-if="Object.keys(project).length" class="project-wrapper">
    <div class="project-bg">
      <div class="project">
        <div>
          <ProjectGeneralInfo :project="project" type="Active Project" />

          <div
            v-if="!!project.lines && !!project.lines.length"
            class="lines-wrapper"
          >
            <h3 class="grey">Scope of Work (SOW)</h3>
            <div class="lines">
              <ProposalLine
                v-for="line in project.lines"
                :key="line.id"
                :line="line"
                :project-id="project.id"
              />
            </div>
          </div>

          <ProjectTextSection :project="project" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectGeneralInfo from "@/components/project/ProjectGeneralInfo.vue";
import ProposalLine from "@/components/ProposalLine.vue";
import ProjectTextSection from "@/components/project/ProjectTextSection.vue";

export default {
  components: { ProjectGeneralInfo, ProposalLine, ProjectTextSection },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
.project {
  display: block;
  min-width: 550px;
  padding: 20px 30px;
  overflow: auto;

  &-wrapper {
    flex-basis: 100%;
    min-height: 100%;
    padding-right: 20px;
  }

  &-bg {
    min-height: calc(100vh - 190px);
    background-color: var(--col-bg-secondary);
  }

  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

hr {
  margin: 20px 0;
}

.lines-wrapper {
  padding: 20px 0;
}
</style>
